<script setup lang="ts">
import { LocalStorageKey } from '@/modules/core/enums'

const { logout, showSignInModal } = useAuth()

const mobileMenuOpenned = ref(false)

const { customer } = useCustomer()

const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')

const name = computed(() => {
  if (!customer.value.name)
    return ''

  return `${customer.value.name || ''} ${customer.value.lastName || ''}`
})

function toggleMobileMenu() {
  mobileMenuOpenned.value = !mobileMenuOpenned.value
}

function openSignInModal() {
  showSignInModal.value = true
}
const locationModalIsVisible = ref(false)
</script>

<template>
  <section>
    <nav class="flex items-center xl:gap-3">
      <Location
        class="size-10 xl:size-auto flex justify-center xl:mr-20"
        :modal-is-visible="locationModalIsVisible"
        @show-modal="locationModalIsVisible = true"
        @close-modal="locationModalIsVisible = false"
      />

      <CartButton class="hidden xl:block" />

      <template v-if="accessToken">
        <NuxtLink
          to="/perfil"
          no-prefetch
          aria-label="Link para acessar o perfil do usuário"
          class="font-bold items-center gap-4 p-2 xl:flex"
        >
          <div class="text-primary-50 min-w-[20px] min-h-[20px] size-10 xl:size-auto flex items-center justify-center">
            <IconAccountCircle />
          </div>

          <span class="hidden text-primary-50 text-base font-normal leading-6 xl:inline">
            Olá, {{ name }}
          </span>
        </NuxtLink>

        <button
          id="logout"
          aria-label="Botão para sair da conta do usuário"
          class="hidden p-2 items-center text-primary-50 xl:flex"
          type="button"
          @click="logout"
        >
          <IconExitToApp />
        </button>
      </template>

      <div v-else class="flex items-center gap-3 cursor-pointer" @click="openSignInModal">
        <div class="text-primary-50 min-w-[20px] min-h-[20px] size-10 xl:size-auto flex items-center justify-center">
          <IconAccountCircle />
        </div>

        <span class="hidden text-primary-50 text-base font-normal leading-6 xl:inline">
          Área do Cliente
        </span>
      </div>

      <NavButton
        :open="mobileMenuOpenned"
        class="xl:hidden"
        @clicked="toggleMobileMenu"
      />
    </nav>

    <THeaderDropdownMenu class="size-12 flex justify-center items-center" :opened-modal="mobileMenuOpenned" @close="mobileMenuOpenned = !mobileMenuOpenned" />
  </section>
</template>
