<script setup lang="ts">
import { LocalStorageKey } from '@/modules/core/enums'

const props = defineProps({
  openedModal: Boolean,
})

const emit = defineEmits(['close'])

const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')

const dropdownMenu = ref()

onClickOutside(dropdownMenu, (event) => {
  const target = event.target as HTMLElement
  if (target instanceof Element && target.classList.contains('nav-button'))
    return

  if (props.openedModal)
    emit('close')
})

const { logout } = useAuth()
function handleLogout() {
  emit('close')

  logout()
}
</script>

<template>
  <teleport to="body">
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeInDown animate__faster"
      leave-active-class="animate__animated animate__fadeOutUp animate__faster"
      mode="out-in"
    >
      <section
        v-if="openedModal"
        ref="dropdownMenu"
        class="fixed inset-0 px-4 mt-16 flex flex-col items-start md:items-center pt-8 md:pt-0 justify-start md:justify-around gap-2 bg-primary-99 z-50 xl:mt-[80px] xl:hidden"
      >
        <THeaderNavLinks @close="$emit('close')">
          <template #more-options>
            <li>
              <CartButton />
            </li>

            <li>
              <button
                v-if="accessToken"
                aria-label="Botão para sair da conta de usuário"
                class="py-2 px-4 flex items-center gap-2"
                @click="handleLogout"
              >
                <Icon name="ic:outline-exit-to-app" class="!hidden xl:block w-[18px] h-[18px] text-primary-50" />
                <span class="text-primary-50 text-sm">
                  Sair
                </span>
              </button>
            </li>
          </template>
        </THeaderNavLinks>
      </section>
    </transition>
  </teleport>
</template>
