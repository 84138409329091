<script setup lang="ts">
import type { ILocation } from '@/modules/location/types/location.d'
import { SearchProcedureOrdenation, SearchProcedureOrdenationMapping } from '@/modules/search/types/enums'

defineProps({
  placeholder: {
    type: String,
    default: 'Buscar por CEP ou Cidade',
  },
})

const emit = defineEmits(['close'])

const term = ref<string>('')
const cities = ref<ILocation[]>([])

function handleSelect(city: ILocation) {
  useLocationParam().value = city.slug

  cities.value = []
  term.value = ''

  useSearchProcedureOrdenation().value = SearchProcedureOrdenationMapping[SearchProcedureOrdenation.RELEVANCE]

  emit('close')
}
</script>

<template>
  <section class="w-full">
    <LocationModalSearchInput
      v-bind="$props"
      autocomplete="off"
      @searched="(citiesSearched: ILocation[]) => cities = citiesSearched"
      @term-updated="(newTerm: string) => term = newTerm"
      @close="$emit('close')"
    />

    <div class="relative">
      <ul
        v-if="Array.isArray(cities) && cities.length"
        class="lg:max-h-[200px] absolute overflow-y-auto w-full bg-white"
      >
        <LocationModalSearchItem
          v-for="city in cities"
          :key="city.slug"
          :city="city"
          :selected="useLocationState().value"
          :term="term"
          @select="handleSelect"
        />
      </ul>
    </div>
  </section>
</template>
