<script setup lang="ts">
import Img from '@/components/Img.vue'

const socialLinks = ref([
  {
    to: 'https://blog.saudetrevo.com.br/',
    name: 'blog',
    icon: 'ic:outline-newspaper',
    alt: 'Botão de link para o Blog da Saúde Trevo',
  },
  {
    to: 'https://www.facebook.com/people/Trevo/100085457634073/',
    name: 'facebook',
    icon: 'ion:social-facebook',
    alt: 'Botão de link para o Facebook da Saúde Trevo',
  },
  {
    to: 'https://www.instagram.com/saudetrevo/',
    name: 'instagram',
    icon: 'ion:logo-instagram',
    alt: 'Botão de link para o Instagram da Saúde Trevo',
  },
  {
    to: 'https://www.linkedin.com/company/trevo-sa%C3%BAde/',
    name: 'linkedin',
    icon: 'bx:bxl-linkedin',
    alt: 'Botão de link para o Linkedin da Saúde Trevo',
  },
])

const menuLinks = ref([
  { link: 'https://blog.saudetrevo.com.br', target: '_blank', name: 'Blog' },
  { link: '/pagamento-protegido', target: '_self', name: 'Pagamento Protegido' },
  { link: '/perfil', target: '_self', name: 'Area do Cliente' },
  { link: '/compra-garantida', name: 'Compra Garantida' },
  { link: '/quem-somos', target: '_self', name: 'Quem Somos' },
])

const termsLinks = ref([
  { link: '/faq', target: '_self', name: 'FAQ' },
  { link: '/politicas-de-privacidade', name: 'Politícas de Privacidade' },
  { link: '/termos-e-condicoes', name: 'Termos e Condições' },
])
</script>

<template>
  <footer class="flex flex-col pt-8 pb-6 px-6 xl:px-0 bg-primary-50 selection:bg-tertiary-50">
    <div class="container mx-auto">
      <section class="flex flex-col xl:flex-row xl:justify-between">
        <div class="flex flex-col h-full xl:h-[inherit]">
          <div class="flex-1">
            <Img
              src="https://web-cdn.saudetrevo.com.br/trevo/trevo_logo_branca.svg"
              alt="Logo de rodapé da Saúde trevo"
              width="108"
              height="34"
              :draggable="false"
              class="w-[108px] xl:w-[134px] h-[34px] xl:h-[42px]"
            />

            <p class="text-sm text-white mt-2">
              Uma boa saúde começa com <strong>boas escolhas!</strong>
            </p>
          </div>

          <ul class="flex gap-3 mt-6 xl:mt-0">
            <li v-for="(link, linkIndex) in socialLinks" :key="`link-${linkIndex}`">
              <NuxtLink
                :to="link.to"
                external
                target="_blank"
                :alt="link.alt"
                no-prefetch
                class="peer flex items-center justify-center border border-transparent text-primary-50 min-w-[28px] min-h-[28px] w-7 h-7 bg-white rounded-full hover:border-white hover:bg-transparent hover:text-white duration-300"
              >
                <span class="sr-only">Icone da rede social: {{ link.name }}</span>

                <Icon :name="link.icon" class="w-5 h-5" />
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div class="flex flex-col mt-6 text-white xl:w-1/2 xl:m-0">
          <div class="flex flex-col xl:flex-row">
            <div>
              <p class="text-white">
                Horário de atendimento
              </p>
              <p class="text-xs my-2 xl:mt-2">
                Segunda a Sexta – 8h às 19h <br>
                Sábado - 8h às 13h
              </p>
            </div>

            <div class="flex xl:mt-2 mt-12 mb-6 xl:ml-28 text-xs xl:text-sm font-semibold">
              <ul class="flex flex-col gap-5 mr-6">
                <li
                  class="cursor-pointer font-xs"
                >
                  <NuxtLink
                    :to="`/${useLocationParam().value}`"
                    alt="link para Inico"
                    no-prefetch
                  >
                    <span class="whitespace-nowrap">
                      Inicio
                    </span>
                  </NuxtLink>
                </li>
                <li
                  v-for="(item, itemIndex) in menuLinks.slice(0, 2)"
                  :key="`item-${itemIndex}`"
                  class="cursor-pointer font-xs"
                >
                  <NuxtLink
                    :to="item.link"
                    :target="item.target"
                    :alt="`link para ${item.name}`"
                    no-prefetch
                  >
                    <span class="whitespace-nowrap">
                      {{ item.name }}
                    </span>
                  </NuxtLink>
                </li>
              </ul>

              <ul class="flex flex-col gap-5">
                <li
                  v-for="(item, itemIndex) in menuLinks.slice(2, 5)"
                  :key="`item-${itemIndex}`"
                  class="cursor-pointer font-xs"
                >
                  <NuxtLink
                    :to="item.link"
                    :target="item.target"
                    :alt="`link para ${item.name}`"
                    no-prefetch
                  >
                    <span class="whitespace-nowrap">
                      {{ item.name }}
                    </span>
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>

          <div class="flex gap-3 mt-6">
            <NuxtLink
              to="https://www.reclameaqui.com.br/empresa/saude-trevo/"
              target="_blank"
              alt="tag do reclame aqui"
              external
              no-prefetch
            >
              <Img
                src="https://web-cdn.saudetrevo.com.br/illustrations/reclame_aqui.svg"
                alt="Selo de qualidade da Saúde Trevo"
                width="110"
                height="28"
              />
            </NuxtLink>
          </div>
        </div>
      </section>

      <hr class="border-primary-60 my-6">

      <section class="flex flex-col xl:flex-row text-white">
        <div class="xl:w-1/2">
          <div class="pb-4 text-xs xl:mr-2">
            © 2024 Saúde Trevo – Todos os direitos reservados <br>
            Razão Social: PROJETO TREVO APOIO E GESTAO DA SAUDE LTDA – CNPJ:
            45.453.448/0001-07
          </div>
        </div>

        <div class="xl:w-1/2">
          <ul class="flex items-center gap-4 text-xs xl:text-sm">
            <li v-for="(tLink, i) in termsLinks" :key="`link-${i}`">
              <NuxtLink
                :to="tLink.link"
                class="text-tertiary-50 my-[10px] font-semibold"
                no-prefetch
              >
                {{ tLink.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </footer>
</template>
